import React from 'react';

import './style.scss';

const SocialButton = props => {
    return (
        <div className="SocialButton">
            <a href={props.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                style={{
                    backgroundColor: props.color,
                    color: props.textColor
                }}>
                <div className="icon">
                    <img src={props.icon} alt={`${props.title} Icon`} />
                </div>
                <div className="content">
                    {props.displayName}
                </div>
            </a>
            <div className="title">
                {props.title}
            </div>
        </div>
    )
}

export default SocialButton