import React, { useEffect } from 'react';

import { SocialButton } from './components';
import PhoneIcon from './media/images/svg/icons8-phone-50.svg'
import EmailIcon from './media/images/svg/icons8-envelope-50.svg'
import InstagramIcon from './media/images/svg/icons8-instagram-50.svg'
import TikTokIcon from './media/images/svg/icons8-tiktok-50.svg'
import SnapchatIcon from './media/images/svg/icons8-snapchat-squared-50.svg'
import YouTubeIcon from './media/images/svg/icons8-youtube-50.svg'
import FacebookIcon from './media/images/svg/icons8-facebook-old-50.svg'
import LinkedInIcon from './media/images/svg/icons8-linkedin-50.svg'
import TwitchIcon from './media/images/svg/icons8-twitch-50.svg'
import SteamIcon from './media/images/svg/icons8-steam-50.svg'

import './App.scss';

function App() {

  const socials = [
    new SocialMedia("Phone Number", "(386) 473-1407", "tel:3864731407", PhoneIcon),
    new SocialMedia("Email", "jess@jessicaocala.com", "mailto:jess@jessicaocala.com", EmailIcon),
    new SocialMedia("Instagram", "@jessicaocala", "https://www.instagram.com/jessicaocala/", InstagramIcon),
    new SocialMedia("TikTok", "@jessicaocala", "https://www.tiktok.com/@jessicaocala", TikTokIcon),
    new SocialMedia("Snapchat", "jessicaocala", "http://www.snapchat.com/add/jessicaocala", SnapchatIcon),
    new SocialMedia("YouTube", "Jessica Ocala", "https://www.youtube.com/c/jessicaocala", YouTubeIcon),
    new SocialMedia("Facebook", "Jessicaocala", "https://www.facebook.com/Jessicaocala", FacebookIcon),
    new SocialMedia("LinkedIn", "jessicaocala", "https://linkedin.com/in/jessicaocala", LinkedInIcon),
    new SocialMedia("Twitch", "jessicaocala", "https://www.twitch.tv/jessicaocala/", TwitchIcon),
    new SocialMedia("Steam", "Twitch.tv/TehMrsFederali", "https://steamcommunity.com/id/jessicaocala", SteamIcon),
  ]

  useEffect(_ => {
  }, [])

  return (
    <div className="App">
      <img src={require('./media/images/jo-logo.png')}
        className="app-logo"
        alt="Jessica Ocala Logo" />
      <div className="icons">
        {socials.map(social => {
          return (
            <SocialButton title={social.title}
              displayName={social.displayName}
              link={social.link}
              icon={social.icon}
              color={social.color}
              textColor={social.textColor} />
          )
        })}
      </div>
      <>
        <script type="text/javascript">
          var sc_project=12453392;
          var sc_invisible=1;
          var sc_security="38a2f339";
        </script>
        <script type="text/javascript"
          src="https://www.statcounter.com/counter/counter.js"
          async></script>
        <noscript><div class="statcounter"><a title="Web Analytics"
          href="https://statcounter.com/" target="_blank"><img
            class="statcounter"
            src="https://c.statcounter.com/12453392/0/38a2f339/1/"
            alt="Web Analytics" /></a></div></noscript>
      </>
    </div>
  );
}

class SocialMedia {
  title = "";
  displayName = "";
  link = "";
  icon = null;
  color = 'rgba(255,255,255,0.5)';
  textColor = 'black';

  constructor(title, displayName, link, icon, color, textColor) {
    this.title = title ? title : this.title
    this.displayName = displayName ? displayName : this.displayName
    this.link = link ? link : this.link
    this.icon = icon ? icon : this.icon
    this.color = color ? color : this.color
    this.textColor = textColor ? textColor : this.textColor
  }
}

export default App;
